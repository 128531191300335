import { IPagination, IPaginationRequest } from "interfaces/pagination";
import { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import Header from "components/common/Header";
import Table from "components/common/Table";
import dayjs from "dayjs";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { ICobraProductSerialNumber } from "interfaces/cobraProductSerialNumber";
import CobraProductSerialNumberServices from "services/cobraProductSerialNumber";
import Swal from "sweetalert2";


const ListProductSerialNumber = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [data, setData] = useState<{
    data: ICobraProductSerialNumber[];
    pagination: IPagination;
  }>({
    data: [],
    pagination: {
      page: 1,
      total_item: 0,
      total_page: 0,
    },
  });
  const [pagination, setPagination] = useState<IPaginationRequest>({
    page: 1,
    per_page: 10,
  });
  const [search, setSearch] = useState<string>("");
  const [filter, setFilter] = useState<{
    date: {
      start: Date | null;
      end: Date | null;
    };
  }>({ date: { start: null, end: null } });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fetchData = useCallback(() => {
    CobraProductSerialNumberServices.getAll(
      token as string,
      {
        per_page: pagination.per_page,
        page: pagination.page,
      },
      {
        search,
        start_date: filter.date.start
          ? dayjs(filter.date.start).format("YYYY-MM-DD")
          : "",
        end_date: filter.date.end
          ? dayjs(filter.date.end).format("YYYY-MM-DD")
          : "",
      }
    )
      .then((res) => {
        let paginationRes: IPagination = res.data.pagination;
        setData({
          data: res.data.data,
          pagination: {
            page: paginationRes.page,
            total_item: paginationRes.total_item,
            total_page: paginationRes.total_page,
          },
        });
        setIsLoading(false);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  }, [token, onAlert, pagination, search, filter]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onDelete = (item: ICobraProductSerialNumber) => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการลบรหัสสินค้า <b>${item.serial_number}</b> ใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ลบ",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        CobraProductSerialNumberServices.delete(token as string, item.id)
          .then((res) => {
            fetchData();
            onAlert("success", "ลบรหัสสินค้าสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Header
        title="Product Serial Number"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/dashboard/cobra`,
          },
          {
            label: "Product Serial Number",
            href: "/product-serial-number/cobra/list",
          },
          {
            label: "List",
          },
        ]}
        actions={[
          <Link to={`/product-serial-number/cobra/import`}>
            <Button variant="contained" size="large">
              <AddIcon
                sx={{
                  fontSize: 14,
                  mr: 1,
                }}
              />
              <Typography>Import Data</Typography>
            </Button>
          </Link>,
        ]}
      />
      <Table
        headers={[
          {
            key: "serial_number",
            label: "SERIAL NUMBER",
            width: 200,
          },
          {
            key: "product",
            label: "CATEGORY",
            render: (value, index, item) => item.product.category,
            width: 150,
          },
          {
            key: "product",
            label: "MODEL",
            render: (value, index, item) => item.product.name,
            width: 250,
          },
          {
            key: "product",
            label: "PRODUCT NAME",
            render: (value, index, item) => item.product_name,
            width: 250,
          },
          {
            key: "shop",
            label: "SHOP",
            render: (value, index, item) => item.shop || "-",
            width: 200,
          },
          {
            key: "member",
            label: "MEMBER",
            render: (value, index, item) =>
              item.member
                ? `${item.member.first_name} ${item.member.last_name}`
                : "-",
            width: 250,
          },
          {
            key: "purchase_date",
            label: "PURCHASE DATE",
            render: (value, index, item) =>
              item.purchase_date
                ? dayjs(item.purchase_date).format("DD/MM/YYYY")
                : "-",
            width: 200,
          },
          {
            key: "actual_shop",
            label: "ACTUAL SHOP",
            render: (value, index, item) => item.actual_shop || "-",
            width: 200,
          },
          {
            key: "warranty_expire_date",
            label: "WARRANTY EXPIRE DATE",
            render: (value, index, item) =>
              item.warranty_expire_date
                ? dayjs(item.warranty_expire_date).format("DD/MM/YYYY")
                : "-",
            width: 250,
          },
          {
            key: "warranty_expire_date",
            label: "Unsync",
            width: 100,
            render(value, index, item) {
              return <ButtonUnsync value={item} fetchData={fetchData} />;
            },
          },
        ]}
        actions={{
          onDetail: (item: any) =>
            navigate(`/product-serial-number/cobra/${item.id}`),
          onEdit: (item: any) =>
            navigate(`/product-serial-number/cobra/${item.id}/edit`),
          onDelete: (item: any) => onDelete(item),
          width: 200,
        }}
        onSearch={(searchText) => setSearch(searchText)}
        filters={[
          {
            type: "daterange",
            name: "Purchase Date",
            value: filter.date,
            onChange: (value: any) =>
              setFilter((filter) => {
                return { ...filter, date: value };
              }),
          },
        ]}
        data={data.data}
        isLoading={isLoading}
        pagination={
          data.pagination
            ? {
                ...data.pagination,
                onChange: (page) =>
                  setPagination((pagination) => {
                    return {
                      ...pagination,
                      page,
                    };
                  }),
              }
            : undefined
        }
      />
    </>
  );
};

const ButtonUnsync = ({
  value,
  fetchData,
}: {
  value: ICobraProductSerialNumber;
  fetchData: () => void;
}) => {
  const { token } = useToken();
  const { onAlert } = useAlert();

  const onUnsync = () => {
    Swal.fire({
      icon: "warning",
      html: `ต้องการยกเลิกลงทะเบียนสินค้าใช่ หรือไม่?`,
      showCancelButton: true,
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        CobraProductSerialNumberServices.unsync(token as string, value.id + "")
          .then((res) => {
            fetchData();
            onAlert("success", "ยกเลิกลงทะเบียนสินค้าสำเร็จ");
          })
          .catch((err) => {
            onAlert("error", err.response.data.message);
          });
      }
    });
  };

  return (
    <>
      <Button
        variant="contained"
        color="error"
        onClick={onUnsync}
        disabled={!value.member}
      >
        ยกเลิก
      </Button>
    </>
  );
};

export default withAuth(
  withPermission(ListProductSerialNumber, "MANAGE_COBRA")
);
