import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import CobraProductSerialNumberServices from "services/cobraProductSerialNumber";
import { ICobraProductSerialNumber } from "interfaces/cobraProductSerialNumber";

const ProductSerialNumberDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [productSerialNumber, setProductSerialNumber] =
    useState<ICobraProductSerialNumber | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraProductSerialNumberServices.getDetail(token as string, id as string)
        .then((res) => {
          setProductSerialNumber(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  if (productSerialNumber) {
    return (
      <>
        <Header
          title="Product Serial Number Details"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Product Serial Number",
              href: `/product-serial-number/cobra/list`,
            },
            {
              label: productSerialNumber.serial_number,
            },
          ]}
        />
        <FieldList
          type="detail"
          fields={[
            {
              label: "Product Serial Number",
              type: "text",
              name: "serial_number",
              defaultValue: productSerialNumber.serial_number,
              col: 6,
            },
            {
              label: "Product Serial Number",
              type: "text",
              name: "product_serial_name",
              defaultValue: productSerialNumber.product_name,
              col: 6,
            },
            {
              label: "Member",
              type: "text",
              name: "member",
              defaultValue: productSerialNumber.member
                ? productSerialNumber.member?.first_name +
                  " " +
                  productSerialNumber.member?.last_name
                : "-",
              col: 6,
            },
            {
              label: "Product",
              type: "text",
              name: "model",
              defaultValue: productSerialNumber.product.name,
              col: 6,
            },
            {
              label: "Shop",
              type: "text",
              name: "shop",
              defaultValue: productSerialNumber.shop,
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Actual Shop",
              type: "text",
              name: "actual_shop",
              defaultValue: productSerialNumber.actual_shop || "-",
              col: 6,
            },
            {
              label: "Purchase Date",
              type: "text",
              name: "purchase_Date",
              defaultValue: productSerialNumber.purchase_date || "-",
              col: 6,
            },
            {
              label: "Warranty Period (Days)",
              type: "number",
              name: "warranty_period",
              defaultValue: productSerialNumber.warranty_period,
              col: 6,
            },
            {
              label: "Warranty Expire Date",
              type: "text",
              name: "warranty_expire_date",
              defaultValue: productSerialNumber.warranty_expire_date || "-",
              col: 6,
            },
          ]}
          onBack={() => navigate(`/product-serial-number/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(
  withPermission(ProductSerialNumberDetail, "MANAGE_COBRA")
);
