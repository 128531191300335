import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import FileServices from "services/file";
import Header from "components/common/Header";
import { IPromotion } from "interfaces/promotion";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraPromotionServices, { TCreatePromotionData } from "services/cobraPromotion";

type TEditPromotion = {
  name: string;
  description: string;
  image: FileList | undefined;
  price: string;
  url: string;
};

const EditPromotion = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [promotion, setPromotion] = useState<IPromotion | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraPromotionServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          setPromotion(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  const onSubmit = async (values: TEditPromotion) => {
    let data: TCreatePromotionData = {
      name: values.name,
      description: values.description.replace("/\r?\n/gi", ""),
      image: promotion?.image as string,
      price: parseInt(values.price),
      url: values.url,
    };
    let isError = false;
    if (values.image && values.image[0]) {
      let formDataImage = new FormData();
      formDataImage.append("name", data.name);
      formDataImage.append("file", values.image[0]);
      formDataImage.append("prefix", "reward");
      formDataImage.append("path", "/reward");
      await FileServices.create(token as string, formDataImage)
        .then((res) => {
          data.image = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (!isError) {
      CobraPromotionServices.edit(
        token as string,
        id as string,
        data
      )
        .then((res) => {
          onAlert("success", "แก้ไขโปรโมชั่นสำเร็จ");
          navigate(`/promotion/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (promotion) {
    return (
      <>
        <Header
          title="Edit Promotion"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Promotion",
              href: `/promotion/cobra/list`,
            },
            {
              label: promotion.name,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Cover Image",
              type: "image",
              name: "image",
              defaultValue: promotion.image,
              col: 6,
              tooltip: "สำหรับกำหนดรูปภาพแสดงผลของรายการโปรโมชั่น",
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Name",
              type: "text",
              name: "name",
              inputOptions: {
                required: "กรุณากรอกชื่อ",
              },
              defaultValue: promotion.name,
              col: 6,
              tooltip: "สำหรับกำหนดชื่อแสดงผลของรายการโปรโมชั่น",
            },
            {
              label: "Price",
              type: "number",
              name: "price",
              inputOptions: {
                required: "กรุณากรอกราคา",
              },
              defaultValue: promotion.price,
              col: 6,
              tooltip: "สำหรับกำหนดราคาแสดงผลของรายการโปรโมชั่น",
            },
            {
              label: "Description",
              type: "textarea",
              name: "description",
              inputOptions: {
                required: "กรุณากรอกคำอธิบาย",
              },
              defaultValue: promotion.description,
              col: 6,
              maxLength: 150,
              tooltip: "สำหรับกำหนดรายละเอียดแสดงผลของรายการโปรโมชั่น",
            },
            {
              label: "URL",
              type: "text",
              name: "url",
              inputOptions: {
                required: "กรุณากรอก URL",
              },
              defaultValue: promotion.url,
              col: 6,
              tooltip:
                "สำหรับกำหนด URL เพื่อไปยังรายการโปรโมชั่นบนเว็บไซต์",
            },
          ]}
          onBack={() => navigate(`/promotion/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditPromotion, "MANAGE_COBRA"));
