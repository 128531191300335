import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { ICobraProduct } from "interfaces/cobraProduct";
import CobraProductServices, { TCreateProductData } from "services/cobraProduct";
import FileServices from "services/file";

type TEditProduct = {
  name: string;
  category: string;
  year: string;
  season: string;
  image: FileList;
};

const EditProduct = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [product, setProduct] = useState<ICobraProduct | null>(null);

  useEffect(() => {
    const fetchData = () => {
      CobraProductServices.getDetail(
        token as string,
        id as string
      )
        .then((res) => {
          setProduct(res.data.data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  const onSubmit = async (values: TEditProduct) => {
    let data: TCreateProductData = {
      name: values.name,
      category: values.category,
      year: values.year,
      season: values.season,
      image: product?.image as string,
    };
    let isError = false;
    if (values.image && values.image[0]) {
      let formDataImage = new FormData();
      formDataImage.append("name", data.name);
      formDataImage.append("file", values.image[0]);
      formDataImage.append("prefix", "cobra-product");
    formDataImage.append("path", "/cobra-product");
      await FileServices.create(token as string, formDataImage)
        .then((res) => {
          data.image = res.data.data;
        })
        .catch((err) => {
          isError = true;
          onAlert("error", err.response.data.message);
        });
    }
    if (!isError) {
      CobraProductServices.edit(
        token as string,
        id as string,
        data
      )
        .then((res) => {
          onAlert("success", "แก้ไข Product Master สำเร็จ");
          navigate(`/product/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  if (product) {
    return (
      <>
        <Header
          title="Edit Product Master"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Product Master",
              href: `/product/cobra/list`,
            },
            {
              label: product.name,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Product Category",
              type: "select",
              name: "category",
              inputOptions: {
                required: "กรุณาเลือกประเภทของ Product",
              },
              col: 6,
              items: [
                {
                  label: "Driver",
                  value: "Driver",
                },
                {
                  label: "Fairway",
                  value: "Fairway",
                },
                {
                  label: "Irons",
                  value: "Irons",
                },
                {
                  label: "Hybrid",
                  value: "Hybrid",
                },
                {
                  label: "Wedge",
                  value: "Wedge",
                },
                {
                  label: "Putter",
                  value: "Putter",
                },
              ],
              defaultValue: product.category,
              tooltip: "ตัวเลือกสำหรับกำหนดประเภทของ Product",
            },
            {
              label: "Model Name",
              type: "text",
              name: "name",
              inputOptions: {
                required: "กรุณากรอกชื่อ",
              },
              col: 6,
              tooltip: "สำหรับกำหนดชื่อแสดงผลของรายการโปรโมชั่น",
              defaultValue: product.name,
            },
            {
              label: "Year",
              type: "text",
              name: "year",
              inputOptions: {
                required: "กรุณากรอกปีของ Product",
              },
              col: 6,
              tooltip: "สำหรับกำหนดปีของ Product",
              defaultValue: product.year,
            },
            {
              label: "Season",
              type: "text",
              name: "season",
              inputOptions: {
                required: "กรุณากรอก Season",
              },
              col: 6,
              maxLength: 150,
              tooltip: "สำหรับกำหนด Season ของ Product",
              defaultValue: product.season,
            },
            {
              label: "Image",
              type: "image",
              name: "image",
              defaultValue: product.image,
              col: 6,
              tooltip: "สำหรับกำหนดรูปภาพแสดงผลของรายการ Product Master",
            },
          ]}
          onBack={() => navigate(`/product/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(withPermission(EditProduct, "MANAGE_COBRA"));
