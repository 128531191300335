import {
  AssignmentInd,
  CalendarMonth,
  Call,
  CheckCircle,
  ConfirmationNumber,
  EventAvailable,
  Home,
  LocalOffer,
  ManageAccounts,
  SportsGolf,
  Store,
  SupervisedUserCircle,
  VerifiedUser,
  ViewListOutlined
} from "@mui/icons-material";

import useResponsive from "hooks/useResponsive";
import { TPermission } from "interfaces/user";
import { ReactNode } from "react";
import SidebarDesktop from "./SidebarDesktop";
import SidebarMobile from "./SidebarMobile";

interface Props {
  drawerWidth: number;
  isOpen: boolean;
  toggleDrawer: (
    isOpen: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

export interface IMenu {
  header?: string;
  permission?: TPermission;
  items: {
    icon: ReactNode;
    label: string;
    href?: string;
    permission?: TPermission;
    subMenu?: {
      icon: ReactNode;
      label: string;
      href: string;
      permission?: TPermission;
    }[];
  }[];
}

const menu: IMenu[] = [
  {
    items: [
      {
        icon: <Home />,
        label: "Dashboard",
        href: "/dashboard",
      },
      {
        icon: <ManageAccounts />,
        label: "Manage Admin",
        permission: "MANAGE_ADMIN",
        subMenu: [
          {
            icon: <SupervisedUserCircle />,
            label: "Admin",
            href: "/admin/list",
          },
          {
            icon: <AssignmentInd />,
            label: "Role",
            href: "/role/list",
          },
          {
            icon: <VerifiedUser />,
            label: "Permission",
            href: "/permission/list",
          },
        ],
      },
    ],
  },
  {
    header: "Cobra",
    permission: "MANAGE_COBRA",
    items: [
      {
        icon: <CalendarMonth />,
        label: "Club Fitting",
        subMenu: [
          {
            icon: <CalendarMonth />,
            label: "Calendar",
            href: "/calendar/cobra",
          },
          {
            icon: <EventAvailable />,
            label: "Booking",
            href: "/booking/cobra/list",
          },
          {
            icon: <ViewListOutlined />,
            label: "Service",
            href: "/service/cobra/list",
          },
        ],
      },
      {
        icon: <SupervisedUserCircle />,
        label: "Manage Member",
        subMenu: [
          {
            icon: <SupervisedUserCircle />,
            label: "Member",
            href: "/member/cobra/list",
          },
          {
            icon: <SupervisedUserCircle />,
            label: "Member Log",
            href: "/member-log/cobra/list",
          },
        ],
      },
      {
        icon: <Call />,
        label: "Manage Contact",
        subMenu: [
          {
            icon: <Call />,
            label: "Contact Type",
            href: "/contact-type/cobra/list",
          },
          {
            icon: <Call />,
            label: "Contact",
            href: "/contact/cobra/list",
          },
        ],
      },
      {
        icon: <Store />,
        label: "Store",
        href: "/store/cobra/list",
      },
      {
        icon: <LocalOffer />,
        label: "Promotion",
        href: "/promotion/cobra/list",
      },
      {
        icon: <CheckCircle />,
        label: "Manage Warranty",
        subMenu: [
          {
            icon: <SportsGolf />,
            label: "Product Master",
            href: "/product/cobra/list",
          },
          {
            icon: <ConfirmationNumber />,
            label: "Product Serial Number",
            href: "/product-serial-number/cobra/list",
          },
        ],
      },
    ],
  },
];

const Sidebar: React.FC<Props> = ({ drawerWidth, isOpen, toggleDrawer }) => {
  return (
    <>
      {useResponsive("up", "lg") ? (
        <SidebarDesktop drawerWidth={drawerWidth} menu={menu} />
      ) : (
        <SidebarMobile
          drawerWidth={drawerWidth}
          menu={menu}
          isOpen={isOpen}
          toggleDrawer={toggleDrawer}
        />
      )}
    </>
  );
};

export default Sidebar;
