import { Navigate, createBrowserRouter } from "react-router-dom";

import Dashboard from "pages/dashboard";
import Layout from "components/common/Layout";
import Login from "pages/login";
import Settings from "pages/settings";
import ClubFittingBookingDetail from "pages/club-fitting/booking/detail";
import manageAdminRouters from "./manageAdminRouters";
import CobraRouters from "./cobraRouters";

const Routers = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/dashboard" replace />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      ...manageAdminRouters,
      ...CobraRouters,
      {
        path: "/booking/club-fitting/:id",
        element: <ClubFittingBookingDetail />,
      },
    ],
  },
]);

export default Routers;
