import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import Header from "components/common/Header/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useToken from "hooks/useToken";
import { IBooking } from "interfaces/booking";
import { Link } from "react-router-dom";
import CobraBookingServices from "services/cobraBooking";

const Dashboard = () => {
  const { token } = useToken();
  const [booking, setBooking] = useState<IBooking[]>([]);
  const [loading, sertLoading] = useState<boolean>(true);

  useEffect(() => {
    CobraBookingServices.getAllBooking(token as string)
      .then((res) => {
        setBooking(res.data.data);
        sertLoading(false);
      })
      .catch((err) => {
        sertLoading(false);
      });
  }, []);

  return (
    <>
      <Header
        title="Dashboard"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: "/dashboard",
          },
          {
            label: "Cobra",
          },
        ]}
      />
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <FullCalendar
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={booking.map((b, index) => {
              let data = [
                index.toString().padStart(20, "0"),
                b.id,
                b.first_name + " " + b.last_name,
                `${b.booking_start_time.substring(
                  0,
                  5
                )} น. - ${b.booking_end_time.substring(0, 5)} น.`,
                b.system,
              ];
              return {
                title: data.join("|"),
                date: b.booking_date,
              };
            })}
            eventContent={EventDetail}
          />
        </div>
      )}
    </>
  );
};

const EventDetail = ({ event }: { event: { title: string } }) => {
  let data = event.title.split("|");
  let id = data[1];
  let user = data[2];
  let time = data[3];
  let system = data[4];
  const content = (
    <Link to={system === "COBRA" ? `/booking/cobra/${id}` : `/booking/club-fitting/${id}`}>
      <Box
        sx={{ p: 1, backgroundColor: system === "COBRA" ? "#2A529C" : "#E62E2A" }}
      >
        <Typography fontWeight={700} display={"inline"}>
          {system === "COBRA" ? "Cobra" : "Club Fitting"}
        </Typography>
        <Typography>
          <Typography fontWeight={700} display={"inline"} fontSize={12}>
            Name:
          </Typography>
          <Typography fontSize={12}>{user}</Typography>
        </Typography>
        <Typography>
          <Typography fontWeight={700} display={"inline"} fontSize={12}>
            Time:
          </Typography>
          <Typography fontSize={12}>{time}</Typography>
        </Typography>
      </Box>
    </Link>
  );
  return content;
};

export default withAuth(withPermission(Dashboard, "MANAGE_COBRA"));
