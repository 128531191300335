import { useEffect, useState } from "react";

import CreateFieldList from "components/booking/CreateFieldList";
import Header from "components/common/Header";
import { IService } from "interfaces/service";
import { ITimeSlot } from "interfaces/booking";
import TimeSlotServices from "services/cobraTimeSlot";
import useAlert from "hooks/useAlert";
import { useNavigate } from "react-router-dom";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraServiceServices from "services/cobraService";
import CobraBookingServices from "services/cobraBooking";

type TCreateBooking = {
  first_name: string;
  last_name: string;
  tel: string;
  email: string;
  booking_date: string;
  services: boolean[];
  start_slot_time_id: number;
};

const CreateBooking = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();
  const [timeSlots, setTimeSlots] = useState<
    {
      label: any;
      value: any;
    }[]
  >([]);
  const [timeSlotsData, setTimeSlotsData] = useState<ITimeSlot[]>([]);
  const [services, setServices] = useState<
    {
      label: any;
      value: any;
    }[]
  >([]);
  const [servicesData, setServicesData] = useState<IService[]>([]);
  const [bookingDate, setBookingDate] = useState<string | null>(null);
  const [allMaxDurationServices, setAllMaxDurationServices] =
    useState<number>(0);
  const [maxDurationServices, setMaxDurationServices] = useState<number>(0);

  useEffect(() => {
    if (bookingDate) {
      TimeSlotServices.getAll(token as string, bookingDate).then((res) => {
        let slotTimes: ITimeSlot[] = res.data.data;
        setTimeSlotsData(slotTimes);
        setTimeSlots(
          slotTimes.map((ts: ITimeSlot, index: number) => {
            return {
              label: `${ts.time_start.substring(0, 5)} น.`,
              value: ts.id,
            };
          })
        );
      });
    }
  }, [bookingDate]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    CobraServiceServices.getAll(token as string, {
      page: 1,
      per_page: 100,
    }).then((res) => {
      let servicesData: IService[] = res.data.data;
      let maxDuration = 0;
      servicesData.forEach((s) => {
        maxDuration += s.duration;
      });
      setAllMaxDurationServices(maxDuration);
      setServicesData(servicesData);
      setServices(
        servicesData.map((s: IService, index: number) => {
          return {
            label: `${s.name} (${s.duration} min)`,
            value: s.id,
          };
        })
      );
    });
  };

  const onSubmit = (data: any) => {
    let dataProps: TCreateBooking = data;
    let allDuration = 0;
    let servicesSelected: any[] = dataProps.services.map(
      (service: boolean, index: number) => {
        if (service) {
          return services[index];
        }
      }
    );
    let servicesSelectedFilter: any[] = servicesSelected.filter(
      (serviceSelected) => serviceSelected !== undefined
    );
    servicesData.forEach((s) => {
      servicesSelectedFilter.forEach((sv) => {
        if (s.id === sv.value) {
          allDuration += s.duration;
        }
      });
    });
    if (allDuration > maxDurationServices) {
      onAlert(
        "error",
        "ไม่สามารถทำการจองได้กรุณาเลือกบริการให้อยู่ในเวลาที่จำกัด"
      );
    } else {
      CobraBookingServices.create(token as string, {
        ...dataProps,
        email: dataProps.email || undefined,
        services: servicesSelectedFilter.map((s) => s.value),
      })
        .then((res) => {
          onAlert("success", "ทำการจองสำเร็จ");
          navigate(`/booking/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", "ไม่สามารถทำการจองได้กรุณาลองใหม่ภายหลัง");
          navigate(`/booking/cobra/list`);
        });
    }
  };

  return (
    <>
      <Header
        title="Create a new booking"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/calendar/cobra`,
          },
          {
            label: "Booking",
            href: "/booking/cobra/list",
          },
          {
            label: "New booking",
          },
        ]}
      />
      <CreateFieldList
        onSubmit={onSubmit}
        fields={[
          {
            label: "First Name",
            type: "text",
            name: "first_name",
            inputOptions: {
              required: "กรุณากรอกชื่อ",
            },
            col: 6,
            tooltip: "สำหรับแสดงชื่อผู้จอง",
          },
          {
            label: "Last Name",
            type: "text",
            name: "last_name",
            inputOptions: {
              required: "กรุณากรอกนามสกุล",
            },
            col: 6,
            tooltip: "สำหรับแสดงนามสกุลผู้จอง",
          },
          {
            label: "Tel",
            type: "text",
            name: "tel",
            inputOptions: {
              required: "กรุณากรอกเบอร์โทรศัพท์",
            },
            col: 6,
            tooltip: "สำหรับแสดงเบอร์โทรศัพท์ผู้จอง",
          },
          {
            label: "Email",
            type: "text",
            name: "email",
            col: 6,
            tooltip: "สำหรับแสดงอีเมลผู้จอง",
          },
          {
            label: "Booking Date",
            type: "date",
            name: "booking_date",
            inputOptions: {
              required: "กรุณาเลือกวันที่จะใช้บริการ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดวันที่จะใช้บริการ",
          },
          {
            label: "Start Time",
            type: "select",
            name: "start_slot_time_id",
            inputOptions: {
              required: "กรุณาเลือกเวลาเริ่ม",
            },
            col: 6,
            items: timeSlots,
          },
          {
            label: `Services ${
              maxDurationServices
                ? `(เลือกได้สูงสุด ${maxDurationServices} นาที )`
                : ""
            }`,
            type: "check-list",
            name: "services",
            inputOptions: {
              atLeastOne: "กรุณาเลือกอย่างน้อย 1 บริการ",
            },
            col: 12,
            items: services,
          },
        ]}
        onBack={() => navigate(`/booking/cobra/list`)}
        onChangeBookingDate={(bookingDate) => setBookingDate(bookingDate)}
        onChangeStartTimeSlot={(value) => {
          timeSlotsData.forEach((ts) => {
            if (ts.id === value) {
              setMaxDurationServices(
                ts.time_slot > allMaxDurationServices
                  ? allMaxDurationServices
                  : ts.time_slot
              );
            }
          });
        }}
      />
    </>
  );
};

export default withAuth(withPermission(CreateBooking, "MANAGE_COBRA"));
