import axios from "axios";

const TimeSlotService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/time-slot`,
});

const TimeSlotServices = {
  getAll: (token: string, date: string) =>
    TimeSlotService.get(`/list?date=${date}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default TimeSlotServices;
