import { IPaginationRequest } from "interfaces/pagination";
import axios from "axios";

export type TCreateProductSerialNumberData = {
  products: {
    serial_number: string;
    product_category: string;
    product_name: string;
    product_serial_name: string;
    warranty_period: number;
    shop: string;
  }[];
};

export type TEditProductSerialNumberData = {
  serial_number: string;
  product_id: number;
  warranty_period: number;
  shop: string;
};

const cobraProductSerialNumberService = axios.create({
  baseURL: `${process.env.REACT_APP_API}/cobra/product-serial-number`,
});

const CobraProductSerialNumberServices = {
  getDetail: (token: string, id: string) =>
    cobraProductSerialNumberService.get(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  getAll: (
    token: string,
    pagination: IPaginationRequest,
    params: {
      search: string;
      start_date: string;
      end_date: string;
    }
  ) =>
    cobraProductSerialNumberService.get("/list", {
      params: {
        perPage: pagination.per_page,
        page: pagination.page,
        search: params.search,
        start_date: params.start_date,
        end_date: params.end_date,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  create: (token: string, data: TCreateProductSerialNumberData) =>
    cobraProductSerialNumberService.post("", data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  edit: (
    token: string,
    id: string | number,
    data: TEditProductSerialNumberData
  ) =>
    cobraProductSerialNumberService.put(`/${id}`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  unsync: (token: string, id: string | number) =>
    cobraProductSerialNumberService.put(`/${id}/unsync`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
  delete: (token: string, id: string | number) =>
    cobraProductSerialNumberService.delete(`/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }),
};

export default CobraProductSerialNumberServices;
