import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import FieldList from "components/common/FieldList";
import Header from "components/common/Header";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import useAlert from "hooks/useAlert";
import useToken from "hooks/useToken";
import { ICobraProduct } from "interfaces/cobraProduct";
import { ICobraProductSerialNumber } from "interfaces/cobraProductSerialNumber";
import CobraProductServices from "services/cobraProduct";
import CobraProductSerialNumberServices from "services/cobraProductSerialNumber";

type TEditProductSerialNumberData = {
  product_serial_name: string;
  serial_number: string;
  product_id: number;
  warranty_period: number;
  shop: string;
};

const EditProductSerialNumber = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { token } = useToken();
  const { onAlert } = useAlert();

  const [productSerialNumber, setProductSerialNumber] =
    useState<ICobraProductSerialNumber | null>(null);
  const [productsDropdown, setProductsDropdown] = useState<
    {
      label: any;
      value: any;
    }[]
  >([]);

  useEffect(() => {
    const fetchData = () => {
      CobraProductServices.getAll(token as string, "", {
        page: 1,
        per_page: 1000,
      }).then((res) => {
        setProductsDropdown(
          res.data.data.map((product: ICobraProduct, index: number) => {
            return {
              label: product.name,
              value: product.id,
            };
          })
        );
      });
      CobraProductSerialNumberServices.getDetail(token as string, id as string)
        .then((res) => {
          const data: ICobraProductSerialNumber = res.data.data
          setProductSerialNumber(data);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    };

    fetchData();
  }, [id, token, onAlert]);

  const onSubmit = async (values: TEditProductSerialNumberData) => {
    let data: TEditProductSerialNumberData = {
      product_serial_name: values.product_serial_name,
      serial_number: values.serial_number,
      product_id: parseInt(values.product_id + ""),
      warranty_period: parseInt(values.warranty_period + ""),
      shop: values.shop
    };
    CobraProductSerialNumberServices.edit(token as string, id as string, data)
      .then((res) => {
        onAlert("success", "แก้ไข Product Serial Number สำเร็จ");
        navigate(`/product-serial-number/cobra/list`);
      })
      .catch((err) => {
        onAlert("error", err.response.data.message);
      });
  };

  if (productSerialNumber) {
    return (
      <>
        <Header
          title="Edit Product Master"
          breadcrumbs={[
            {
              label: "Dashboard",
              href: `/dashboard`,
            },
            {
              label: "Cobra",
              href: `/dashboard/cobra`,
            },
            {
              label: "Product Serial Number",
              href: `/product-serial-number/cobra/list`,
            },
            {
              label: productSerialNumber.serial_number,
            },
          ]}
        />
        <FieldList
          type="edit"
          onSubmit={onSubmit}
          fields={[
            {
              label: "Product Serial Number",
              type: "text",
              name: "serial_number",
              inputOptions: {
                required: "กรุณากรอก Serial Number",
              },
              tooltip: "สำหรับกำหนด Serial Number",
              defaultValue: productSerialNumber.serial_number,
              col: 6,
            },
            {
              label: "Product Name",
              type: "text",
              name: "product_serial_name",
              inputOptions: {
                required: "กรุณากรอก Product Name",
              },
              tooltip: "สำหรับกำหนด Product Name",
              defaultValue: productSerialNumber.product_name,
              col: 6,
            },
            {
              label: "Member",
              type: "text",
              name: "member_id",
              col: 6,
              defaultValue: productSerialNumber.member ? productSerialNumber.member?.first_name + " " + productSerialNumber.member?.last_name : "-",
              disabled: true,
            },

            {
              label: "Product",
              type: "select",
              name: "product_id",
              inputOptions: {
                required: "กรุณาเลือก Product",
              },
              items: productsDropdown,
              col: 6,
              tooltip: "สำหรับกำหนด Product",
              defaultValue: productSerialNumber.product.id,
            },
            {
              label: "Shop",
              type: "text",
              name: "shop",
              defaultValue: productSerialNumber.shop || "-",
              col: 6,
            },
            {
              label: "",
              type: "box",
              name: "",
              col: 6,
            },
            {
              label: "Acutual Shop",
              type: "text",
              name: "actual_shop",
              defaultValue: productSerialNumber.actual_shop || "-",
              col: 6,
              disabled: true,
            },
            {
              label: "Purchase Date",
              type: "text",
              name: "purchase_date",
              defaultValue: productSerialNumber.purchase_date || "-",
              col: 6,
              disabled: true,
            },
            {
              label: "Warranty Period (Days)",
              type: "number",
              name: "warranty_period",
              inputOptions: {
                required: "กรุณากรอก Warranty Period",
                min: {
                  value: 1,
                  message: "กรุณากรอก Warranty Period อย่างน้อย 1 วัน",
                },
              },
              defaultValue: productSerialNumber.warranty_period,
              col: 6,
            },
            {
              label: "Warranty Expire Date",
              type: "text",
              name: "warranty_expire_date",
              defaultValue: productSerialNumber.warranty_expire_date || "-",
              col: 6,
              disabled: true,
            },
          ]}
          onBack={() => navigate(`/product-serial-number/cobra/list`)}
        />
      </>
    );
  } else {
    return <></>;
  }
};

export default withAuth(
  withPermission(EditProductSerialNumber, "MANAGE_COBRA")
);
