import { useNavigate } from "react-router-dom";

import FieldList from "components/common/FieldList";
import FileServices from "services/file";
import Header from "components/common/Header";
import useAlert from "hooks/useAlert";
import { useEffect } from "react";
import useToken from "hooks/useToken";
import withAuth from "hocs/withAuth";
import withPermission from "hocs/withPermission";
import CobraPromotionServices, { TCreatePromotionData } from "services/cobraPromotion";

type TCreatePromotion = {
  name: string;
  description: string;
  image: FileList;
  price: string;
  url: string;
};

const CreatePromotion = () => {
  const navigate = useNavigate();
  const { token } = useToken();
  const { onAlert } = useAlert();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {};

  const onSubmit = async (values: TCreatePromotion) => {
    let data: TCreatePromotionData = {
      name: values.name,
      description: values.description,
      image: "",
      price: parseInt(values.price),
      url: values.url,
    };
    let isError = false;
    let formDataImage = new FormData();
    formDataImage.append("name", data.name);
    formDataImage.append("file", values.image[0]);
    formDataImage.append("prefix", "promotion");
    formDataImage.append("path", "/promotion");
    await FileServices.create(token as string, formDataImage)
      .then((res) => {
        data.image = res.data.data;
      })
      .catch((err) => {
        isError = true;
        onAlert("error", err.response.data.message);
      });
    if (!isError) {
      CobraPromotionServices.create(token as string, data)
        .then((res) => {
          onAlert("success", "สร้างโปรโมชั่นสำเร็จ");
          navigate(`/promotion/cobra/list`);
        })
        .catch((err) => {
          onAlert("error", err.response.data.message);
        });
    }
  };

  return (
    <>
      <Header
        title="Create a new promotion"
        breadcrumbs={[
          {
            label: "Dashboard",
            href: `/dashboard`,
          },
          {
            label: "Cobra",
            href: `/dashboard/cobra`,
          },
          {
            label: "Promotion",
            href: "/promotion/cobra/list",
          },
          {
            label: "New promotion",
          },
        ]}
      />
      <FieldList
        type="create"
        onSubmit={onSubmit}
        fields={[
          {
            label: "Cover Image",
            type: "image",
            name: "image",
            inputOptions: {
              required: "กรุณาใส่รูปภาพประกอบ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดรูปภาพแสดงผลของรายการโปรโมชั่น",
          },
          {
            label: "",
            type: "box",
            name: "",
            col: 6,
          },
          {
            label: "Name",
            type: "text",
            name: "name",
            inputOptions: {
              required: "กรุณากรอกชื่อ",
            },
            col: 6,
            tooltip: "สำหรับกำหนดชื่อแสดงผลของรายการโปรโมชั่น",
          },
          {
            label: "Price",
            type: "number",
            name: "price",
            inputOptions: {
              required: "กรุณากรอกราคา",
            },
            col: 6,
            tooltip: "สำหรับกำหนดราคาแสดงผลของรายการโปรโมชั่น",
          },
          {
            label: "Description",
            type: "textarea",
            name: "description",
            inputOptions: {
              required: "กรุณากรอกคำอธิบาย",
            },
            col: 6,
            maxLength: 150,
            tooltip: "สำหรับกำหนดรายละเอียดแสดงผลของรายการโปรโมชั่น",
          },
          {
            label: "URL",
            type: "text",
            name: "url",
            inputOptions: {
              required: "กรุณากรอก URL",
            },
            col: 6,
            tooltip:
              "สำหรับกำหนด URL เพื่อไปยังรายการโปรโมชั่นบนเว็บไซต์",
          },
        ]}
        onBack={() => navigate(`/promotion/cobra/list`)}
      />
    </>
  );
};

export default withAuth(withPermission(CreatePromotion, "MANAGE_COBRA"));
